import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import {
  View,
  StyleSheet,
  ScrollView,
  TouchableWithoutFeedback
} from "react-native";
import { imgVisbility, imgVisbilityOff } from "./assets";
// Customizable Area End

import ReadermodeController, {
  Props,
  configJSON,
} from "./ReadermodeController";

export default class Readermode extends ReadermodeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
        data-test-id="touchableWithoutFeedback"
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          <View>
            <Typography
              data-test-id="labelBody" 
              style={styles.body} 
            >
              {configJSON.labelBodyText}
            </Typography>
            <View style={styles.bgPasswordContainer}>
              <Input 
              data-test-id="txtInput"
              placeholder={configJSON.txtInputPlaceholder}
              label={`This is the received value: ${this.state.txtSavedValue}`}
              {...this.txtInputProps}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  {...this.btnShowHideProps}
                  style={{ backgroundColor: "" }}
                  icon={this.state.enableField ? (
                    <img src={imgVisbility} />
                  ) : (
                    <img src={imgVisbilityOff} />
                  )}
                />
              }
              />
            </View>

            <Button
              data-test-id="btnExample"
              text={configJSON.btnExampleTitle} 
              {...this.btnReaderModeWebProps} 
            />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  body: {
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8,
    padding: 10,
    fontSize:20,
    color:'#000',
    alignItems:'center',
    textAlignVertical:'center'
  },
  bgPasswordContainer: {
    marginBottom: 16,
    padding: 10,
  },
  bgMobileInput: {
    flex: 1,
  },
  showHide: {
    backgroundColor: "transparent",
    marginRight: -10,
  },
  imgShowhide: {},
});


// Customizable Area End
