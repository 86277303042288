Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchMethod = "PATCH";
exports.deleteMethod = "DELETE";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automatedpublishing";
exports.labelBodyText = "automatedpublishing Body";
exports.autopubcontent = "Check AutomatedPublishing Content";
exports.btnExampleTitle = "CLICK ME";
exports.createContentAPIEndPoint = "bx_block_automate_publishing/automate_publishes/"
exports.getContentAPIEndPoints = "bx_block_automate_publishing/courses";
exports.updateAdminContAPIEndPoint = "bx_block_automate_publishing/automate_publishes/";
exports.deleteContentAPIEndPoint = "bx_block_automate_publishing/automate_publishes";
exports.createNewPubDateAPIEndPoint = "bx_block_automate_publishing/automate_publishes/";
exports.contentid = "Content ID";
exports.title = "Title";
exports.uploadedContent = "Uploaded Content";
exports.publishdate = "Publish Date";
exports.edit = "Edit";
exports.Delete = "Delete";
exports.upload = "Upload";
exports.agesuccessmsg = "Updated Date Successfully";
exports.createpubdate = "Created Date Successfully";
exports.SelectDate="Select Date :- ";
exports.Confirm="Confirm";
exports.Cancel="Cancel";
exports.uploadNow = "Upload";
exports.admin = "Admin";
exports.uploadContentAPIEndPoints= "bx_block_automate_publishing/automate_publishes"
exports.description = "Description";
exports.image = "Image";
// Customizable Area End