import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Dispatch, SetStateAction } from "react";
import { SelectChangeEvent } from "@mui/material/Select";

// Customizable Area Start
const {
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  PercentCrop,
} = require("react-image-crop");
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

interface Image {
  // Customizable Area Start
  mime: string;
  data: string;
  width: number;
  height: number;
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  image: { base64: string; width: number; height: number } | null;
  modalOpen: boolean;
  crop:
    | {
        x: number;
        y: number;
        width: number;
        height: number;
        unit: "px" | "%";
      }
    | undefined;
  completedCrop:
    | {
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        unit?: "px" | "%";
      }
    | undefined;
  imgSrc: string | undefined;
  loginToken: string;
  accountList: Array<object>;
  selectedAccount: string | null;
  isUpload: boolean;
  isImageSelect: boolean;
  accountPickerOpen: boolean;
  accountValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class CustomProfileHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imageRef: React.RefObject<HTMLImageElement> | null = null;
  getProfileHeaderApiCallId: string | null = null;
  updateProfileHeaderApiCallId: string | null = null;
  saveCustomHeaderImageApiCallId: string = "";
  getProfileImageHeaderApiCallID: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      image: null,
      modalOpen: false,
      crop: undefined,
      completedCrop: undefined,
      imgSrc: undefined,
      loginToken: "",
      accountList: [],
      selectedAccount: null,
      isUpload: false,
      isImageSelect: false,
      accountPickerOpen: false,
      accountValue: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAccountGroup();
    this.getToken();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    // Customizable Area Start
    if (!this.isPlatformWeb()) {
      if (prevState.isUpload !== this.state.isUpload && this.state.isUpload) {
        Alert.alert("", configJSON.profileUpdated, [
          {
            text: configJSON.testOK,
            onPress: () => this.setIsUpload(false),
          },
        ]);
      }
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  accountToDropDown = (
    data: Array<{
      attributes: { name: string; accounts: Array<{ id: string }> };
      id: string;
    }>
  ) => {
    return data.map(
      (item: {
        attributes: { name: string; accounts: Array<{ id: string }> };
        id: string;
      }) => ({
        value: item.id,
        label: item.attributes.name,
        account: item.attributes.accounts.map(
          (item: { id: string }) => item.id
        ),
      })
    );
  };
  // Customizable Area End

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ loginToken: this.state.loginToken }, () =>
        this.getAccountGroup()
      );
    }
    // Customizable Area Start
    // Get data of account
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileHeaderApiCallId !== null &&
      apiRequestCallId === this.getProfileHeaderApiCallId
    ) {
      this.getProfileHeaderApiCallId = null;
      if (responseJson && responseJson.data && !responseJson.error) {
        this.setState({
          accountList: this.accountToDropDown(responseJson.data),
        });
        //Some Message on empty account
        if (responseJson.data?.length === 0) {
          this.parseApiCatchErrorResponse(configJSON.emptyAccount);
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    // update account imaage
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateProfileHeaderApiCallId !== null &&
      apiRequestCallId === this.updateProfileHeaderApiCallId
    ) {
      this.updateProfileHeaderApiCallId = null;
      if (responseJson && responseJson.data) {
        this.setIsUpload(true);
        this.getAccountGroup();
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // get image profile
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProfileImageHeaderApiCallID !== null &&
      apiRequestCallId === this.getProfileImageHeaderApiCallID
    ) {
      this.getProfileImageHeaderApiCallID = null;
      if (responseJson && responseJson.data && !responseJson.error) {
        this.setState({
          image: {
            base64:
              responseJson.data.attributes.settings === null
                ? ""
                : responseJson.data.attributes.settings.profile_image,
            width:
              responseJson.data.attributes.settings === null
                ? 0
                : configJSON.imageWidth,
            height:
              responseJson.data.attributes.settings === null
                ? 0
                : configJSON.imageHeight,
          },
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.setState({
          image: {
            base64: "",
            width: 0,
            height: 0,
          },
        });
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  getAccountGroup = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileHeaderApiCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customProfileHeaderEndpoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  getProfileImage = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.loginToken,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileImageHeaderApiCallID = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customProfileHeaderEndpoint}/${this.state.selectedAccount}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  };

  openImagePicker = async (
    event?: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (this.isPlatformWeb()) {
      if (event && event.target.files && event.target.files.length > 0) {
        this.setState({ crop: undefined });
        const reader = new FileReader();
        //@ts-ignore
        reader.addEventListener("load", () =>
          this.setState({
            imgSrc: reader.result?.toString() || "",
            modalOpen: true,
          })
        );
        reader.readAsDataURL(event.target.files[0]);
      }
      return;
    }

    const ImagePicker = require("react-native-image-crop-picker");
    const image: Image = await ImagePicker.openPicker({
      width: configJSON.imageWidth,
      height: configJSON.imageHeight,
      cropping: true,
      includeBase64: true,
      includeExif: true,
    });
    this.setState({
      image: {
        base64: `data:${image.mime};base64,` + image.data,
        width: image.width,
        height: image.height,
      },
      isImageSelect: true,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  cropImage = () => {
    const { completedCrop: crop } = this.state;
    const image = this.imageRef?.current || null;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    const scaleX = (image?.naturalWidth || 0) / (image?.width || 0);
    const scaleY = (image?.naturalHeight || 0) / (image?.height || 0);
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(
      ((crop && crop.width) || 0) * scaleX * pixelRatio
    );
    canvas.height = Math.floor(
      ((crop && crop.height) || 0) * scaleY * pixelRatio
    );

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";

    const cropX = ((crop && crop.x) || 0) * scaleX;
    const cropY = ((crop && crop.y) || 0) * scaleY;

    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image as CanvasImageSource,
      0,
      0,
      image?.naturalWidth || 0,
      image?.naturalHeight || 0,
      0,
      0,
      image?.naturalWidth || 0,
      image?.naturalHeight || 0
    );
    const base64 = canvas.toDataURL("image/jpeg");
    this.setState({
      image: {
        base64,
        width: configJSON.selectImageWidth,
        height: configJSON.selectImageHeight,
      },
      modalOpen: false,
      isImageSelect: true,
    });
  };

  onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    );
    this.setState({ crop });
  };

  uploadImage = async () => {
    const headers = {
      "Content-Type": configJSON.saveCustomProfileHeaderContentType,
      token: this.state.loginToken,
    };
    let selectAccountDetails:
      | {
          label?: string | undefined;
          account?: string[] | undefined;
        }
      | undefined = {};
    selectAccountDetails = this.state.accountList?.find(
      (item: { value?: string }) => item.value === this.state.selectedAccount
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfileHeaderApiCallId = requestMessage.messageId;
    const body = {
      group: {
        name: selectAccountDetails?.label as string,
        settings: { profile_image: this.state.image?.base64 },
        account_ids: selectAccountDetails?.account,
      },
    };

    this.saveCustomHeaderImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customProfileHeaderEndpoint}/${this.state.selectedAccount}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateCustomProfileHeaderMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  cropModalWebProps = {
    onClose: this.handleModalClose,
  };

  cropPickerWebProps = {
    onChange: (_: unknown, percentCrop: typeof PercentCrop) =>
      this.setState({ crop: percentCrop }),
    onComplete: (completedCrop: typeof PixelCrop) =>
      this.setState({ completedCrop }),
    aspect: configJSON.aspectRatio,
  };

  cropImageWebProps = {
    onLoad: this.onImageLoad,
  };

  cropSubmitButtonWebProps = {
    onClick: this.cropImage,
  };

  handleAccountChange = (event: SelectChangeEvent) => {
    this.handleSelectAccount(event.target.value as string);
  };

  handleSelectAccount = (value: string) => {
    this.setState(
      {
        selectedAccount: value,
        isImageSelect: false,
      },
      () => {
        // get Profile image through Api
        this.getProfileImage();
      }
    );
  };

  setIsUpload = (value: boolean) => {
    this.setState({
      isUpload: value,
    });
  };

  setAccountPickerOpen = (open: boolean) => {
    this.setState({
      accountPickerOpen: open,
    });
  };

  setAccountPickerValue = (callback: Dispatch<SetStateAction<string>>) => {
    this.setState({
      accountValue: callback(this.state.accountValue) as unknown as string,
    });
  };
  // Customizable Area End
}
