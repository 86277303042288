import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper, // Customizable Area End
  Modal,
  FormControl,
} from "@mui/material";

import withStyles from '@mui/styles/withStyles';

// Customizable Area Start
import {
  Theme,
} from "@mui/material/styles";
// Customizable Area End

import AutomatedpublishingController, {
  Props,
  configJSON,
  // Customizable Area Start
  UploadedContent,
  // Customizable Area End
} from "./AutomatedpublishingController";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export class Automatedpublishing extends AutomatedpublishingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {this.state.uploadedContent.map(
          (rowData: UploadedContent, index: number) => (
            <div key={index} style={{ width: "50%", height: "50%" }}>
              {
                this.state.userRole === configJSON.admin ?
                  <div style={{ width: "100%",padding: "20px", border: "1px solid black", borderRadius: "10px", marginBottom: "30px" }}>
                    <div>{rowData.attributes.title}</div>
                    <div>{rowData.attributes.description}</div>
                    <div>
                      {rowData.attributes.files.map((file, index) => (
                        <div key={index}>
                          {file.content_type === "application/pdf" ? (
                            <iframe
                              src={file.image_url}
                              style={{ width: "100%", height: "500px" }}
                              title="PDF Document"
                            />
                          ) : (
                            <img
                              style={{ width: "100%" }}
                              src={file.image_url}
                              alt="file"
                            />
                          )}
                        </div>
                      ))}
                    </div>


                  </div>
                  :
                  <>
                   <div>NO Content Available</div>
                  </>
              }
            </div>
          ))
        }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle = {
  UploadBtn: {
    height: "45",
    marginTop: "20px",
    border: "none",
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
    "&:hover": {
      backgroundColor: "rgb(98, 0, 238)",
    },
  },

  imgStyle: {
    height: "50px",
    width: "50px",
  },
  textStyle: {
    marginTop: "20px",
  },
  formStyle: {
    border: "1px solid black",
    borderRadius: "5px",
  },
  date_time: {
    "& .form-control": {
      width: "100%",
      padding: "8px 12px",
      background: "none",
      border: "1px solid black",
      borderRadius: "4px",
    },
  },
};

export default withStyles(webStyle)(Automatedpublishing);
// Customizable Area End
