Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Trash";
exports.backTitleText= "Trash List";
exports.labelBodyText = "Item List";
exports.deleteApiMethod="DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.listItemsApiEndpoint ="/bx_block_trash/items"
exports.trashListItemsApiEndpoint="bx_block_trash/deleted_items"
// Customizable Area End