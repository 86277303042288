import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { View, StyleSheet, Image, Dimensions} from "react-native";
import { imgVisbility, imgVisbilityOff, backArrow, deleteButton } from "./assets";

// Customizable Area End

import TrashController, {
  Props,
  configJSON,
} from "./TrashController";

export default class Trash extends TrashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={{ display: "flex", alignItems: "center" }}>
        <View style={styles.mainWrapper}>
          <Typography style={styles.titleText}>{configJSON.labelTitleText}</Typography>
          <Typography style={styles.bodyText}>{configJSON.labelBodyText}</Typography>
          <View style={styles.inputStyle}>
            <Input
              data-test-id="txtInput"
              placeholder={configJSON.txtInputPlaceholder}
              label={`This is the received value: ${this.state.txtSavedValue}`}
              {...this.txtInputProps}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  {...this.btnShowHideProps}
                  style={{ backgroundColor: "" }}
                  icon={this.state.enableField ? (
                    <img src={imgVisbility} />
                  ) : (
                    <img src={imgVisbilityOff} />
                  )}
                />
              }
            />
          </View>
          <View style={styles.buttonStyle}>
            <Button
              data-test-id="btnAddExample"
              gradientColors={[styles.buttonStyle.backgroundColor, styles.buttonStyle.backgroundColor]}
              onPress={() => this.doButtonPressed()}
              text={configJSON.btnExampleTitle} />
          </View>
          <View style={{ marginBottom: 100, width: "100%" }}>
            {
              this.state.showTrashList ?
                <View>
                  <View style={{ flexDirection: 'row', padding: 10, marginBottom: 20 }}>
                    <Button
                      data-test-id="toggleImageChecked"
                      style={{ width: '30%' }} onPress={() => this.setTrashListHide()}
                      icon={
                        <Image
                          source={backArrow}
                          style={{ width: '25%', height: 20 }}
                          resizeMode="cover"
                        />
                      }
                    >
                    </Button>
                    <Typography
                      data-test-id="labelBody"
                    >{configJSON.backTitleText}</Typography>
                  </View>
                  <View style={styles.listContainer}>
                    {this.state.trashListItem.map((item, index) => {
                      let { image_url, video_url } = item.item_attributes;
                      const mediaUrl = image_url || video_url;
                      const mediaType = this.getMediaType(mediaUrl);
                      const formattedDate = this.formatDeletedDate(item.deleted_at);

                      return (
                        <View key={index}>
                          <View style={styles.imageContainer1}>
                            {this.handle(
                              mediaType,
                              () => (
                                <Image
                                  style={styles.imageStyle}
                                  source={{ uri: mediaUrl }}
                                  resizeMode="cover"
                                />
                              ),
                              () => (
                                <video
                                  style={styles.imageStyle}
                                  src={mediaUrl}
                                  controls
                                  loop
                                />
                              ),
                              () => (
                                <Button
                                  data-test-id="pdfdownload"
                                  text="Open PDF"
                                  onPress={() => this.downloadAndOpenPdf(mediaUrl)}
                                />
                              )
                            )}
                          </View>
                          <View style={styles.imageContainer1}>
                            <Typography>Deleted Date:</Typography>
                            <Typography>{formattedDate}</Typography>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
                :
                <>
                  <View style={styles.TitleContainer}>
                    <Typography
                      data-test-id="labelBody"
                      style={styles.body}
                    >
                      {configJSON.labelBodyText}
                    </Typography>
                    <Button testID={"btnShowTrash"}
                      style={styles.deleteContainer}
                      onPress={() => this.setTrashListShow()}
                      text={configJSON.labelTitleText}
                    >
                    </Button>
                  </View>
                  <View style={styles.listContainer}>
                    {this.state.listItem.map((item, index) => {
                      let { image, video, } = item.attributes;
                      const mediaUrl = image || video;
                      const mediaType = this.getMediaType(mediaUrl);
                      return (
                        <View key={item.id} style={styles.imageContainer1}>
                          {this.handleMedia(
                            mediaType,
                            () => (
                              <Image
                                style={styles.imageStyle}
                                source={{ uri: mediaUrl }}
                                resizeMode="cover"
                              />
                            ),
                            () => (
                              <video
                                style={styles.imageStyle}
                                src={mediaUrl}
                                controls
                                loop
                              />
                            ),
                            () => (
                              <Button
                                text="Open PDF"
                                onPress={() => this.downloadAndOpenPdf(mediaUrl)}
                              />
                            )
                          )}

                          <Button
                            testID={"btnDelete" + index}
                            style={{ width: '50px', height: "50px", marginLeft: 5 }}
                            onPress={() => this.deleteListItem(item.id)}
                            icon={
                              <Image
                                source={deleteButton}
                                style={{ width: 30, height: 30, }}
                                resizeMode="cover"
                              />
                            }

                          >
                          </Button>
                        </View>
                      );
                    })}
                  </View>
                </>
            }
          </View>
        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  listContainer: {
    flex: 1,
  },
  imageContainer1: {
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 10,
  },
  imageStyles: {
    height: Dimensions.get("window").width / 3,
    width: '100%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  TitleContainer: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row"
  },
  body: {
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8,
    padding: 10,
  },
  imageStyle: {
    height: Dimensions.get("window").width / 3,
    width: '90%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  deleteContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 1
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End