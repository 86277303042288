import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { imgVisbility, imgVisbilityOff } from "./assets";
import {
  Dimensions,
  View,
  Text,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  TextInput,
  Modal,
  TouchableOpacity
} from "react-native";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./Richtexteditor.css";
import {
  MenuProvider,
} from 'react-native-popup-menu';
// Customizable Area End

import Richtexteditor1Controller, {
  Props,
  configJSON,
} from "./Richtexteditor1Controller";

export default class Richtexteditor1 extends Richtexteditor1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container} keyboardDismissMode={'none'}
        testID="scroll-view"
        ref={this.scrollRef}
        nestedScrollEnabled={true}
        scrollEventThrottle={20}>
        <TouchableWithoutFeedback
          data-test-id="touchableWithoutFeedback"
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          <View>
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.linkModel}
              testID='model_add'
            >
              <View style={{ flex: 1, backgroundColor: 'rgba( 0, 0, 0, 0.4)', borderRadius: 8, paddingHorizontal: 10, }}>
                <View style={styles.centeredView}>
                  <Text testID="cancel_modal_button" style={styles.headding} >
                    Insert Link
                  </Text>
                  <Text style={styles.title}>Enter the link url</Text>
                  <TextInput
                    testID="linkurl"
                    placeholder={'Enter the link url'}
                    value={this.state.link}
                    onChangeText={this.onChangeTitle}
                    style={styles.input}
                  />
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 20 }}>
                    <TouchableOpacity testID={"btnOk"} style={{ width: '45%', paddingVertical: 10, alignItems: 'center', borderColor: 'gray', borderWidth: 1, borderRadius: 20 }} onPress={this.onLinkDone} >
                      <Text data-test-id="labelBody" style={{ textAlign: 'center', color: '#000', fontSize: 16, fontWeight: 'bold' }}>Ok</Text>
                    </TouchableOpacity>
                    <TouchableOpacity testID={"btnCnacel"} style={{ width: '45%', paddingVertical: 10, alignItems: 'center', borderColor: 'gray', borderWidth: 1, borderRadius: 20 }} onPress={this.onInsertLink} >
                      <Text data-test-id="labelBody" style={{ textAlign: 'center', color: '#000', fontSize: 16, fontWeight: 'bold' }}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>

              </View>
            </Modal>
            <Typography
              data-test-id="labelBody"
              style={styles.body}
            >
              {configJSON.labelBodyText}
            </Typography>
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
              <MenuProvider >
                <Editor
                  editorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder="please input content"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                    inline: { inDropdown: false },
                    list: { inDropdown: false },
                    textAlign: { inDropdown: false },
                    link: { inDropdown: false },
                    history: { inDropdown: false },
                    fontFamily: {
                      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                    },
                  }}
                />
              </MenuProvider>
            </KeyboardAvoidingView>
            <View style={styles.bgPasswordContainer}>
              <Input
                data-test-id="txtInput"
                style={styles.bgMobileInput}
                placeholder={configJSON.txtInputPlaceholder}
                label={`This is the received value: ${this.state.txtSavedValue}`}
                {...this.txtInputProps}
                rightIcon={
                  <Button
                    data-test-id="btnShowHide"
                    aria-label="toggle password visibility"
                    {...this.btnShowHideProps}
                    style={{ backgroundColor: "" }}
                    icon={this.state.enableField ? (
                      <img src={imgVisbility} />
                    ) : (
                      <img src={imgVisbilityOff} />
                    )}
                  />
                }
              />
            </View>

            <Button
              data-test-id="btnExample"
              text={configJSON.btnExampleTitle}
              {...this.btnExampleProps}
            />
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },


  wrapperClass: {
    padding: 16,
    borderWidth: 1,
    borderColor: "#ccc"
  },
  editorClass: {
    backgroundColor: "lightgray",
    padding: 16,
    borderWidth: 1,
    borderColor: "#ccc"
  },
  toolbarClass: {
    borderWidth: 1,
    borderColor: "#ccc"
  },



  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 850,
    backgroundColor: "#ffffffff",
  },
  body: {
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8,
    padding: 10,
  },
  bgPasswordContainer: {
    marginBottom: 16,
    padding: 10,
  },
  bgMobileInput: {
    flex: 1,
  },
  nav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 5,
  },
  rich: {
    minHeight: 250,
    flex: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#e3e3e3',
  },
  topVi: {
    backgroundColor: '#fafafa',
  },
  richBar: {
    borderColor: '#efefef',
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  richBarDark: {
    backgroundColor: '#191d20',
    borderColor: '#696969',
  },
  scroll: {
    backgroundColor: '#ffffff',
  },
  scrollDark: {
    backgroundColor: '#2e3847',
  },
  darkBack: {
    backgroundColor: '#191d20',
  },
  item: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#e8e8e8',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 15,
  },

  input: {
    marginTop: 10,
    width: '100%',
    height: 40,
    borderColor: 'gray',
    borderRadius: 20,
    borderWidth: 1,
    paddingLeft: 15,
    paddingRight: 15
  },

  tib: {
    textAlign: 'center',
    color: '#515156',
  },

  flatStyle: {
    paddingHorizontal: 12,
  },
  showHide: {
    backgroundColor: "transparent",
    marginRight: -10,
  },
  centeredView: {
    padding: 15,
    backgroundColor: "white",
    alignSelf: 'center',
    justifyContent: 'center',
    width: '90%',
    marginTop: '20%',
    borderRadius: 20
  },
  headding: {
    color: "black",
    fontWeight: "bold",
    fontSize: 24,
    marginTop: 10
  },
  title: {
    color: "black",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 10
  },
  imgShowhide: {},
});


// Customizable Area End
