import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, createRef } from "react";
import { RichEditor,FONT_SIZE} from "react-native-pell-rich-editor";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ScrollView } from "react-native";
import ImagePicker,{Image} from 'react-native-image-crop-picker';
import { EditorState } from "draft-js";
import FontFamilyStylesheet from './stylesheet';


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  theme:string,
  disabled:boolean,
  initHTML:string,
  colors : string[],
  selectedColor:string,
  bgcolor:string,
  fonts:string[],
  fontSize:string,
  fontFamily:string[],
  colorModels:boolean,
  linkModel:boolean,
  link:string,
  editorState: EditorState;
  selectedfontFamily:string,
  content: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Richtexteditor1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  richText:RefObject<RichEditor>
  scrollRef:RefObject<ScrollView>
  private editorRef: RefObject<RichEditor>;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.editorRef = createRef<RichEditor>();

    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      fontSize:'5',
      theme:'',
      initHTML: ``,
      disabled:false,
      colorModels:false,
      linkModel:false,
      link:'',
      fontFamily:['Arial',
        'Calibri',
        'Candara',
        'Century Gothic',
        'Consolas',
        'Dejavu Sans',
        'Franklin Gothic',
        'Gill Sans',
        'Helvetica',
        'Lucida Sans',
        'MS Sans Serif',
        'Neue Helvetica',
        'Optima',
        'Segoe UI',
        'Tahoma',
        'Trebuchet MS',
        'Verdana'],
      colors : ["black", "white", "gray", "silver", "maroon", "red", "purple", "fushsia", "green", "lime", "olive", "yellow", "navy", "blue", "teal", "aqua"],
      fonts : ["5", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
      selectedColor:'black',
      bgcolor:'white',
      editorState: EditorState.createEmpty(),
      selectedfontFamily: 'Arial',
      content: '<p>Hello, start editing!</p>',

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.richText = React.createRef();
    this.scrollRef= React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (this.editorRef.current) {
      this.editorRef.current.setContentHTML(`
        <style>${FontFamilyStylesheet}</style>
        <div style="font-family: ${this.state.selectedfontFamily};">${this.state.content}</div>
      `);
    }
    this.btnEditorDefualtProps()
  }
btnEditorDefualtProps=()=>{
    this.handleFontSize('5');
    this.handleFontName('Arial');
    this.onColorSelectorClicked('blue');
    this.onBcColorSelectorClicked('black')
    this.handleCursorPosition(10)
  }
  handleFontSize = (item:string) => {
    this.richText.current?.setFontSize( parseInt(item) as FONT_SIZE);
    this.setState({fontSize:item})
  };
  handleFontName = (item:string) => {
    this.richText.current?.injectJavascript(`document.body.querySelectorAll("*").forEach((e) => {e.style.fontFamily = "${item}";})`)
    this.setState({selectedfontFamily:item})
    this.changeFontFamily(item)
  };

  changeFontFamily = (newFontFamily: string) => {
    this.setState({selectedfontFamily: newFontFamily}, () => {
      if (this?.editorRef?.current) {
        this?.editorRef?.current?.setContentHTML(`
          <style>${FontFamilyStylesheet}</style>
          <div style="font-family: ${this.state.selectedfontFamily};">${this.state.content}</div>
        `);
        }
    });
  };


 


   handleCursorPosition = (scrollY: number) => {
    this.scrollRef.current?.scrollTo({y: scrollY - 30, animated: true});
  };
 
   onPressAddImage = async() => {
   let image:Image= await ImagePicker.openPicker({
      width: 300,
      height: 300,
      includeBase64: true,
      includeExif: true,
    })
    this.richText.current?.insertImage(`data:${image.mime};base64,${image?.data}`);
  
  };
  onColorSelectorClicked=(item:string)=>{
  
    this.setState({selectedColor:item})
    this.richText.current?.setForeColor(item)
    }
  onBcColorSelectorClicked=(item:string)=>{
    this.setState({bgcolor:item})
    this.richText.current?.setHiliteColor(item)
    }
    onLinkDone =() => {
      this.setState({linkModel:!this.state.linkModel})
      this.richText.current?.insertLink(this.state.link, this.state.link);
    };
    onInsertLink=()=>{
      this.setState({linkModel:!this.state.linkModel,link:''})
    }
    onChangeTitle=(text:string)=>{
      this.setState({link:text})
    }
    onEditorStateChange = (editorState: any) => {
      this.setState({ editorState });
    };
  // Customizable Area End
}
