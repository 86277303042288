Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.customProfileHeaderEndpoint = "account_groups/groups";
exports.saveCustomProfileHeaderContentType = "application/json";
exports.saveCustomProfileHeaderMethod = "POST";
exports.validationApiMethodType = "GET";
exports.updateCustomProfileHeaderMethod = "PUT";

exports.accounDropDowntTitle = "Select account";
exports.chooseImageButtonTitle = "Choose an image for the header image";
exports.saveImageButtonTitle = "Upload image";
exports.cropImageButtonTitle = "Crop Image";
exports.emptyAccount = "No account groups present so can’t set header image";
exports.profileUpdated = "Profile header image will be updated successfully";
exports.testOK = "OK";
exports.aspectRatio = 16 / 9;
exports.imageWidth = 1600;
exports.imageHeight = 900;
exports.selectImageWidth = 320;
exports.selectImageHeight = 180;
// Customizable Area End
