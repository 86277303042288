import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
  Modal,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ICourse } from './types/types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End
import PtCoursesScreenController, {
  Props,
  configJSON,
} from './PtCoursesScreenController';

export default class PtCoursesScreen extends PtCoursesScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAvailableCourses = () => {
    const { availableCourses } = this.state;
    return (
      <>
        <Button
          data-test-id={'createCourseButton'}
          title={'Create Course'}
          variant="contained"
          onClick={() => this.showCreateCourseModal()}
        >
          {configJSON.createCourseText}
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.courseNameText}</TableCell>
              <TableCell>{configJSON.descriptionText}</TableCell>
              <TableCell>{configJSON.viewCourseTestsText}</TableCell>
              <TableCell>{configJSON.viewStudentListText}</TableCell>
              <TableCell>{configJSON.deleteCourseText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-test-id="availableCourses">
            {availableCourses &&
              availableCourses.map((course: ICourse, index: number) => (
                <TableRow key={`course-${index}`}>
                  <TableCell>{course.attributes.name}</TableCell>
                  <TableCell>{course.attributes.description}</TableCell>
                  <TableCell>
                    <Button
                      data-test-id={`viewTestsButton${index}`}
                      title={'View Tests'}
                      variant="contained"
                      onClick={() => this.navigateToViewTestsForCourse(course)}
                    >
                      {configJSON.viewTestsText}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      data-test-id={`viewStudentsButton${index}`}
                      title={'View Students'}
                      variant="contained"
                      onClick={() =>
                        this.navigateToViewStudentsOnCourse(course)
                      }
                    >
                      {configJSON.viewStudentsText}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      data-test-id={`deleteCourseButton${index}`}
                      title={'Delete Course'}
                      placeholder={'Delete Course'}
                      variant="contained"
                      onClick={() => this.deleteCourse(course.attributes.id)}
                    >
                      {configJSON.deleteCourseText}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </>
    );
  };

  renderCreateCourseModal = () => (
    <Modal
      open={this.state.createCourseModalVisible}
      onClose={this.hideCreateCourseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={webStyle.modal}
    >
      <FormGroup>
        <FormControl>
          <FormLabel>{configJSON.courseNameText}</FormLabel>
          <Input
            data-test-id="courseNameInput"
            placeholder={'Enter Course Name'}
            onChange={(event) =>
              this.setCourseNameInput(event.target.value ?? '')
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>{configJSON.descriptionText}</FormLabel>
          <Input
            data-test-id="courseDescriptionInput"
            placeholder={'Enter Course Description'}
            onChange={(event) =>
              this.setCourseDescriptionInput(event.target.value ?? '')
            }
          />
        </FormControl>
        <Box component="button" sx={webStyle.buttonStyle}>
          <Button
            data-test-id={'createCourseSubmitButton'}
            title={'Create course'}
            variant="contained"
            onClick={() =>
              this.createCourse(
                this.state.courseNameInput,
                this.state.courseDescriptionInput
              )
            }
          >
            {configJSON.createCourseText}
          </Button>
        </Box>
        <Button
          data-test-id={'btnCloseModal'}
          onClick={() => {
            this.hideCreateCourseModal();
          }}
          variant="contained"
        >
          {configJSON.closeText}
        </Button>
      </FormGroup>
    </Modal>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={'md'}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Box sx={webStyle.inputStyle}>
                {this.renderAvailableCourses()}
                {this.renderCreateCourseModal()}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: 'Roboto-Medium',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    display: 'flex',
    width: '100%',
    marginTop: '40px',
    border: 'none',
  },
  modal: {
    backgroundColor: 'lightblue',
    alignSelf: 'center',
    alignItems: 'center',
    width: '75%',
    justifyContent: 'space-between',
    margin: '2em',
  },
};
// Customizable Area End
