Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.DocumentUploadApiEndPoint = "share/share";
exports.GetSharedDocumentsApiEndPoint = "share/share/shared_with_me";
exports.DocumentUploadApiMethod = "POST";
exports.GetSharedDocumentsApiMethod = "GET";
exports.ApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Document Distribution";
exports.labelBodyText = "DocumentDistribution Body";
exports.SharedDocumentBtn = "Go to Shared document";
exports.enterAccountIdToShare = "Enter account ID to share";
exports.UploadBtn = "Upload!";
exports.id = "id";
exports.FileName = "File name";
exports.SharedToId = "Shared to id";
exports.AccountId = "Account Id";
exports.Url = "Url";
exports.SharedDocumentTitle = "Shared Document";
exports.selectFile = "Select File";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
