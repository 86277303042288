import React from "react";

// Customizable Area Start
import { Container, Box, adaptV4Theme } from "@mui/material";
// Customizable Area End

import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import PDFTronWrapper from "./PDFTronWrapper";

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
}));
// Customizable Area End

import PdfEditController, { Props, configJSON } from "./PdfEditController";





export default class PdfEdit extends PdfEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container>
            <Box sx={webStyle.mainWrapper}>
              <PDFTronWrapper
                document={
                  "https://pdftron.s3.amazonaws.com/downloads/pl/PDFTRON_about.pdf"
                }
              />
              <PDFTronWrapper
                document={
                  "https://filesamples.com/samples/document/docx/sample4.docx"
                }
              />
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
