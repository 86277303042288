import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

interface LoadContent {
  attributes: {
    id: string;
  };
  automate_publish: {
    id: string;
    publish_date: string;
  };
}

interface DeleteMessage {
  message: string;
}

interface UpdateResponse {
  data: [
    {
      id: string;
      type: string,
      attributes: {
        id: string;
        title: string;
        description: string;
        files:{
          "id": string;
          "content_type": string;
          image_url:string
      }[];
       
      };
    }
  ];
}

interface RowData {
  id: string;
  attributes: {
    id: string;
    is_publish:boolean
    title: string;
    publish_date: string;
    };
}

export interface UploadedContent {
  id: string;
      type: string,
      attributes: {
        id: string;
        title: string;
        description: string;
        files:{
          "id": string;
          "content_type": string;
          image_url: string;
      }[];
       
      };
    
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    UploadBtn: string;
    imgStyle: string;
    textStyle: string;
    formStyle: string;
    date_time: string;
  };
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadedContent:{ id: string;
  type: string,
  attributes: {
    id: string;
    title: string;
    description: string;
    files:{
      "id": string;
      "content_type": string;
      image_url: string;
  }[];
   
  };
}[] ;
  dateChange: string;
  contentId: string;
  open: boolean;
  createID: string;
  createNewDate: boolean;
  userRole: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomatedpublishingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUploadedContentsAPICallID: string = "";
  deleteContentAPICallId: string = "";
  UpdateAdminContentAPICallId: string = "";
  createNewPubDateAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadedContent: [],
      dateChange: "",
      contentId: "",
      open: false,
      createID: "",
      createNewDate: false,
      userRole: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.setState({
      userRole: await getStorageData("userRole") || configJSON.admin
    })
    await this.getUploadedContents();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getUploadedContentsAPICallID) {
        this.handleContentResponse(apiResponse);
      }
      if (apiRequestCallId === this.UpdateAdminContentAPICallId) {
        await this.handleUpdateContentResponse(apiRequestCallId);
      }
      if (apiRequestCallId === this.deleteContentAPICallId) {
       this.handleDeleteContentAPIResponse(apiResponse);
      }
      if (apiRequestCallId === this.createNewPubDateAPICallID) {
        await this.handleCreateNewDateResponse(apiResponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgs = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgs.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgs);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setLocalStorage = (rowData: RowData) => {
    let publishDate = rowData?.attributes.publish_date;
    this.setState(
      {
        dateChange: publishDate ? publishDate : "",
        contentId: rowData?.attributes.id,
        createID: rowData?.attributes?.id,
        createNewDate: publishDate ? true : false,
      },
      () => {
        this.setState({ open: true });
      }
    );
  };

  getPublishDate = (item: string) => {
    if (item !== undefined && item !== null) {
      return item;
    } else {
      return "---";
    }
  };

  onDateChange = (date: moment.Moment | string) => {
    const formattedDate = typeof date === 'string' ? date : date.format('DD-MM-YYYY');
    this.setState({ dateChange: formattedDate });
  };
  closeModal = () => {
    this.setState({ open: false });
  };

  onChangePubDate = () => {
    if (!this.state.createNewDate) {
      this.createNewPubDate();
    } else {
      this.updateAdminContentAPI();
    }
  };

  getUploadedContents = async () => {
    const header = {
    
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUploadedContentsAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContentAPIEndPoints
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAdminContentAPI = async () => {
    const token = await getStorageData("AutoPubToken");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody = {
      automate_publish: {
        publish_date: this.state.dateChange,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateAdminContentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAdminContAPIEndPoint}${this.state.contentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteContent = async (deleteID: string) => {
  
    const token = await getStorageData("AutoPubToken");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteContentAPIEndPoint}/${deleteID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createNewPubDate = async () => {
    const token = await getStorageData("AutoPubToken");
    const header = {
      token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      automate_publish: {
        publish_date: this.state.dateChange,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createNewPubDateAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createNewPubDateAPIEndPoint}${this.state.createID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleContentResponse = (json: UpdateResponse) => {
    if (json.data.length>0) { 
      this.setState({ uploadedContent:json.data.reverse()})
  }
  };

  handleUpdateContentResponse = async (json: LoadContent) => {
    if (json) {
      this.setState({ open: false });
      await this.getUploadedContents();
      toast.success(configJSON.agesuccessmsg);
    }
  };

  handleDeleteContentAPIResponse = (json: DeleteMessage) => {
     this.getUploadedContents();
  };

  handleCreateNewDateResponse = (json: LoadContent) => {

      this.setState({ open: false });
       this.getUploadedContents();
  };
  // Customizable Area End
}
